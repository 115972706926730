import { Controller } from "stimulus";
import Accordion from '@accede-web/accordion';

export default class extends Controller {
  static targets = [];

  connect() {
    const accordion = new Accordion(this.element);
    accordion.mount();
  }
}
