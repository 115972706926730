import { Controller } from "stimulus";
import lottie from "lottie-web";

export default class extends Controller {
  static targets = ["image"];

  connect() {
    const animation = this.data.get("animation");

    const lottieAnimation = lottie.loadAnimation({
      container: this.imageTarget,
      path: animation,
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  }
}
